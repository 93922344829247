import React, { useRef, useState, useEffect } from 'react';
import { GoogleMap, Autocomplete, Marker } from '@react-google-maps/api';

const mapContainerStyle = {
  height: '400px',
  width: '100%',
};

const libraries = ['places'];

const GoogleMapComponentPick = ({ onSelectLocation, selectedLocation }) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapType, setMapType] = useState('satellite'); // Add map type state
  const autocompleteRef = useRef(null);
  const [mapCenter, setMapCenter] = useState(selectedLocation || { lat: 11.1380481, lng: 77.3249964 });
  const [markerPosition, setMarkerPosition] = useState(selectedLocation || null);
  const [zoomLevel, setZoomLevel] = useState(selectedLocation ? 15 : 10);
  const [address, setAddress] = useState(selectedLocation?.formattedAddress || '');

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (!window.google) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCPU3d7-55UX8Tuuai8fndwapmTFZ2-3rc&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = () => setMapLoaded(true);
        document.head.appendChild(script);
      } else {
        setMapLoaded(true);
      }
    };

    loadGoogleMapsScript();
  }, []);

  useEffect(() => {
    if (selectedLocation && mapLoaded) {
      setMapCenter({ lat: selectedLocation.latitude, lng: selectedLocation.longitude });
      setMarkerPosition({ lat: selectedLocation.latitude, lng: selectedLocation.longitude });
      setZoomLevel(15);
      setAddress(selectedLocation.formattedAddress);
    }
  }, [selectedLocation, mapLoaded]);

  const geocodeAddress = async (address) => {
    if (window.google && window.google.maps) {
      const geocoder = new window.google.maps.Geocoder();
      return new Promise((resolve, reject) => {
        geocoder.geocode({ address }, (results, status) => {
          if (status === 'OK' && results[0]) {
            resolve(results);
          } else {
            reject(status);
          }
        });
      });
    } else {
      console.error('Google Maps API is not loaded.');
      throw new Error('Google Maps API is not loaded.');
    }
  };

  const geocodeLocation = async (lat, lng) => {
    if (window.google && window.google.maps) {
      const geocoder = new window.google.maps.Geocoder();
      const latLng = new window.google.maps.LatLng(lat, lng);
      return new Promise((resolve, reject) => {
        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === 'OK' && results[0]) {
            resolve(results);
          } else {
            reject(status);
          }
        });
      });
    } else {
      console.error('Google Maps API is not loaded.');
      throw new Error('Google Maps API is not loaded.');
    }
  };

  const onPlaceChanged = async () => {
    const autocomplete = autocompleteRef.current;
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const newPosition = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        setMapCenter(newPosition);
        setMarkerPosition(newPosition);
        setZoomLevel(15);
        setAddress(place.formatted_address);

        if (onSelectLocation) {
          onSelectLocation({
            formattedAddress: place.formatted_address,
            latitude: newPosition.lat,
            longitude: newPosition.lng,
            placeId: place.place_id,
          });
        }
      }
    }
  };

  const handleInputChange = async (e) => {
    const newAddress = e.target.value;
    setAddress(newAddress);

    if (newAddress.trim() === '') {
      setMapCenter({ lat: 11.1380481, lng: 77.3249964 });
      setMarkerPosition(null);
      setZoomLevel(15);
      return;
    }

    try {
      const results = await geocodeAddress(newAddress);
      const newPosition = {
        lat: results[0].geometry.location.lat(),
        lng: results[0].geometry.location.lng(),
      };
      setMapCenter(newPosition);
      setMarkerPosition(newPosition);
      setZoomLevel(15);

      if (onSelectLocation) {
        onSelectLocation({
          formattedAddress: results[0].formatted_address,
          latitude: newPosition.lat,
          longitude: newPosition.lng,
          placeId: results[0].place_id,
        });
      }

    } catch (status) {
      console.error('Geocode was not successful for the following reason: ' + status);
    }
  };

  const handleMapClick = async (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    const newPosition = { lat, lng };

    try {
      const results = await geocodeLocation(lat, lng);
      const formattedAddress = results[0].formatted_address;
      setAddress(formattedAddress);
      setMapCenter(newPosition);
      setMarkerPosition(newPosition);
      setZoomLevel(15);

      if (onSelectLocation) {
        onSelectLocation({
          formattedAddress,
          latitude: lat,
          longitude: lng,
          placeId: results[0].place_id,
        });
      }
    } catch (status) {
      console.error('Geocode was not successful for the following reason: ' + status);
    }
  };

  const toggleMapType = () => {
    setMapType((prevType) => (prevType === 'roadmap' ? 'satellite' : 'roadmap'));
  };

  if (!mapLoaded) {
    return <div>Loading Map...</div>;
  }

  return (
    <div style={{ position: 'relative', height: '400px', width: '100%' }}>

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={mapCenter}
        zoom={zoomLevel}
        onClick={handleMapClick}
        mapTypeId={mapType} 
      >
        
        <Autocomplete
          onLoad={(autocomplete) => {
            autocompleteRef.current = autocomplete;
          }}
          onPlaceChanged={onPlaceChanged}
        >
          
          <input
            type="text"
            value={address}
            onChange={handleInputChange}
            placeholder="Search for a location"
            style={{
              width: '100%',
              padding: '0.5rem',
              boxSizing: 'border-box',
              borderRadius: '4px',
              border: '1px solid #ccc',
              position: 'absolute',
              top: '10px',
              left: '10px',
              zIndex: '1000',
            }}
            aria-label="Search for a location"
          />
        </Autocomplete>
        {markerPosition && <Marker position={markerPosition} />}
      </GoogleMap>
      <button
        type="button"
        onClick={toggleMapType}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          padding: '0.5rem',
          borderRadius: '4px',
          zIndex: '1000',
        }}
      >
        {mapType === 'roadmap' ? 'Satellite View' : 'Roadmap View'}
      </button>
    </div>
  );
};

export default GoogleMapComponentPick;
