import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';
import '../../styles/Customer.css'; // Adjust path to CSS file as needed
import Navbar from '../Navbar/Navbar';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import GoogleMapComponent from '../../components/GoogleMap/GoogleMap';
import UserSearchDropdown from '../UserSearchDropdown/UserSearchDropdown';
import { notification } from 'antd';

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [editCustomerData, setEditCustomerData] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [newCustomerData, setNewCustomerData] = useState({
    name: '',
    mobileNumber: '',
    // address: '',
    // role: 'CUSTOMER'
  });


  const [addressFormData, setAddressFormData] = useState({
    address: '',
    userId: '',
    latitude: 0,
    longitude: 0,
    formattedAddress: '',
    placeId: '',
  });

  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    page: '',
    size: '',
    totalElements: '',
    totalPages: '',
  });

  const handleLocationSelect = (location) => {
    setAddressFormData(prevData => ({
      ...prevData,
      latitude: location.latitude,
      longitude: location.longitude,
      formattedAddress: location.formattedAddress,
      placeId: location.placeId,
      address: location.formattedAddress
    }));

    console.log(addressFormData);
  };

  // Function to fetch customers
  const fetchCustomers = async () => {
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }
      console.log('Access Token:', access_token);

      const response = await axios.get(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}customer/list?page=${pagination.page}&size=${pagination.size}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );

      if (Array.isArray(response.data.content)) {
        setCustomers(response.data.content);
        setPagination({
          hasNextPage: response.data.hasNextPage,
          hasPreviousPage: response.data.hasPreviousPage,
          page: response.data.page,
          size: response.data.size,
          totalElements: response.data.totalElements,
          totalPages: response.data.totalPages,
        });
      } else {
        console.error('Unexpected data format:', response.data);
        setCustomers([]);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching customers:', error);
      setError('Error fetching customers. Please try again later.');
      setLoading(false);
    }
  };

  const fetchAddresses = async () => {
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }
      console.log('Access Token:', access_token);

      const response = await axios.get(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.ADDRESS_URL}/${selectedCustomer.id}/list`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );

      if (Array.isArray(response.data.content)) {
        setCustomers(response.data.content);

      } else {
        console.error('Unexpected data format:', response.data);
        setCustomers([]);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching customers:', error);
      setError('Error fetching customers. Please try again later.');
      setLoading(false);
    }
  };

  // Fetch customers on component mount
  useEffect(() => {
    fetchCustomers();
  }, [pagination.page, pagination.size]);

  const handlePageChange = (event, value) => {
    setPagination((prev) => ({
      ...prev,
      page: value,
    }));
  };

  const handleEditClick = (customer) => {
    setEditCustomerData(customer);
    setShowEditPopup(true);
  };

  const handleEditCustomer = async (event) => {
    event.preventDefault();

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      console.log('Access Token for Edit:', access_token);
      console.log('Edit Customer Data:', editCustomerData);

      const response = await axios.put(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}customer/${editCustomerData.id}`,
        editCustomerData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        const updatedCustomers = customers.map(cust =>
          cust.id === editCustomerData.id ? editCustomerData : cust
        );
        setCustomers(updatedCustomers);
        setShowEditPopup(false);
        notification.success({
          message: 'Success',
          description: 'Customer edited successfully!',
          duration: 1,
        });
      } else {
        console.error('Failed to edit customer:', response);
        notification.error({
          message: 'Error',
          description: 'Failed to edit customer!',
          duration: 1,
        });
      }
    } catch (error) {
      console.error('Error editing customer:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to edit customer!',
        duration: 1,
      });
    }
  };


  const handleAddAddress = async (event) => {
    event.preventDefault();

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }
      console.log('Access Token for Add:', access_token);
      console.log('New Customer Data:', newCustomerData);

      const headers = {
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'application/json'
      };
      console.log('Request Headers:', headers);

      const response = await axios.post(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.ADDRESS_URL}`,
        addressFormData,
        { headers }
      );

      if (response.status === 200) {
        console.log(response.data.message);
        setShowAddPopup(false);
        notification.success({
          message: 'Success',
          description: 'Address added successfully!',
          duration: 1,
        });
        fetchCustomers();
      } else {
        console.error('Failed to add address:', response.data);
        notification.error({
          message: 'Error',
          description: 'Failed to add address!',
          duration: 1,
        });
      }
    } catch (error) {
      console.error('Error adding address:', error);
      setError('Error adding address. Please try again later.');
      notification.error({
        message: 'Error',
        description: 'Error adding address!',
        duration: 1,
      });
    }
  };

  const handleAddAddressClick = (customer) => {
    setAddressFormData(prevData => ({
      ...prevData,

    }));
    setShowPopup(true);
  };

  const handleDeleteClick = (customer) => {
    setCustomerToDelete(customer);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setShowModal(false);

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        console.error('No token found');
        throw new Error('No token found');
      }

      console.log('Attempting to delete customer with ID:', customerToDelete.id);

      const response = await axios.delete(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}${customerToDelete.id}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );

      console.log('Delete request response:', response);

      if (response.status === 200) {
        console.log('Customer deleted successfully:', customerToDelete.id);
        notification.success({
          message: 'Success',
          description: 'Customer deleted successfully!',
          duration: 1,
        });
        const updatedCustomers = customers.filter(cust => cust.id !== customerToDelete.id);
        setCustomers(updatedCustomers);
      } else {
        console.error('Failed to delete customer:', response);
        notification.error({
          message: 'Error',
          description: 'Failed to delete customer!',
          duration: 1,
        });
      }
    } catch (error) {
      console.error('Error deleting customer:', error);
      notification.error({
        message: 'Error',
        description: 'Error deleting customer',
        duration: 1,
      });
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
  };

  const handleAddCustomer = async (event) => {
    event.preventDefault();

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }
      console.log('Access Token for Add:', access_token);
      console.log('New Customer Data:', newCustomerData);

      const headers = {
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'application/json'
      };
      console.log('Request Headers:', headers);

      const response = await axios.post(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}customer`,
        newCustomerData,
        { headers }
      );

      if (response.status === 200) {
        console.log(response.data.message);
        setShowAddPopup(false);
        notification.success({
          message: 'Success',
          description: 'Customer created successfully!',
          duration: 1,
        });
        fetchCustomers();
      } else {
        console.error('Failed to add customer:', response.data);
        notification.error({
          message: 'Error',
          description: 'Failed to ad customer',
          duration: 1,
        });
      }
    } catch (error) {
      console.error('Error adding customer:', error);
      // setError('Error adding customer. Please try again later.');
      notification.error({
        message: 'Error',
        description: 'Error adding customer',
        duration: 1,
      });
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCustomers = customers.filter(
    (customer) =>
      customer && customer.name && customer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddCustomerClick = () => {
    setNewCustomerData({
      name: '',
      mobileNumber: '',
      Address: '',
      role: 'CUSTOMER'
    });
    setShowAddPopup(true);
  };

  const handleClosePopup = () => {
    setShowAddPopup(false);
  };

  const handleCustomerSelection = (user) => {
    setSelectedCustomer(user);
    console.log('Selected Customer ID:', user.id); // Access the customer ID
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Navbar />

      <div className="customer-list-container">
        <div className="customer-list-actions">
          <input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input06"
          />
          <h1>Customer List</h1>
          <button className="add-customer-button" onClick={handleAddCustomerClick}>Add Customer</button>
        </div>
        <div className="table-responsive">
          <table className="customer-list-table">
            <thead>
              <tr>
                <th>S.No</th> {/* Added header for serial number */}
                <th>ID</th>
                <th>Name</th>
                <th>Mobile number</th>
                {/* <th>Address</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredCustomers.map((customer, index) => (
                <tr key={customer.id}>
                  <td data-label="S.No">{index + 1}</td> {/* Serial number column */}
                  <td data-label="ID">{customer.id}</td>
                  <td data-label="Name">{customer.name}</td>
                  <td data-label="Mobile number">{customer.mobileNumber}</td>
                  {/* <td data-label="Address">{customer.address}</td> */}
                  <td data-label="Actions">
                    <div className="action-buttons-container">
                      <button className="edit-button19" onClick={() => handleEditClick(customer)}>Edit</button>
                      <button className="delete-button19" onClick={() => handleDeleteClick(customer)}>Delete</button>
                      <button className="add-address-button" onClick={() => handleAddAddressClick(customer)}>Add Address</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>


        {/* Add Customer Popup */}
        {showAddPopup && (
          <div className="customer-popup">
            <div className="customer-popup-content">
              <h2>Add Customer</h2>
              <form onSubmit={handleAddCustomer}>

                {/* Name */}
                {/* <div className="search-bar-wrapper">
              <UserSearchDropdown
                onUserSelect={handleCustomerSelection}
                userName={selectedCustomer?.name || ''}
              />
            </div> */}

                <div className="form-group-cust">
                  <label>Name:</label>
                  <input
                    type="text"
                    value={newCustomerData.name}
                    onChange={(e) => setNewCustomerData({ ...newCustomerData, name: e.target.value })}
                    required
                  />
                </div>

                <div className="form-group-cust">
                  <label>Mobile Number</label>
                  <input
                    type="value"
                    value={newCustomerData.mobileNumber}
                    onChange={(e) => setNewCustomerData({ ...newCustomerData, mobileNumber: e.target.value })}
                    required
                    pattern="\d{10}"
                    maxLength="10"
                  />
                </div>

                {/* Buttons */}
                <div className="button-container32">
                  <button type="submitss" className="save-button32">Add</button>
                  <button type="button" className="cancel-button32" onClick={handleClosePopup}>Cancel</button>
                  <button className="close-button32" onClick={handleClosePopup}>Close</button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Edit Customer Popup */}
        {showEditPopup && (
          <div className="customer-popup">
            <div className="customer-popup-content">
              <h2>Edit Customer</h2>
              <form onSubmit={handleEditCustomer}>

                <div className="form-group-cust">
                  <label>Name:</label>
                  <input
                    type="text"
                    value={editCustomerData.name}
                    onChange={(e) => setEditCustomerData({ ...editCustomerData, name: e.target.value })}
                    required
                  />
                </div>

                {/* Mobile Number */}
                <div className="form-group-cust">
                  <label>Mobile Number</label>
                  <input
                    type="value"
                    value={editCustomerData.mobileNumber}
                    onChange={(e) => setEditCustomerData({ ...editCustomerData, mobileNumber: e.target.value })}
                    required
                    pattern="\d{10}"
                    maxLength="10"
                  />
                </div>

                {/* Buttons */}
                <div className="button-container32">
                  <button type="submitss" className="save-button32">Update</button>
                  <button type="button" className="cancel-button32" onClick={() => setShowEditPopup(false)}>Cancel</button>
                  <button className="close-button32" onClick={() => setShowEditPopup(false)}>Close</button>
                </div>

              </form>
            </div>
          </div>
        )}

        {showPopup && (
          <div className="customer-popup1">
            <div className="customer-popup-content1">
              <h2>Add Address</h2>
              <form onSubmit={handleAddAddress}>
                <div className="form-group-cust1">
                  <label>Door No:</label>
                  <input
                    type="text"
                    value={addressFormData.doorNo}
                    onChange={(e) => setAddressFormData({ ...addressFormData, doorNo: e.target.value })}
                    required
                  />

                  <label>Contact Person:</label>
                  <input
                    type="text"
                    value={addressFormData.contactPerson}
                    onChange={(e) => setAddressFormData({ ...addressFormData, contactPerson: e.target.value })}
                    required
                  />

                  <label>Contact Number:</label>
                  <input
                    type="tel"
                    value={addressFormData.contactNumber}
                    onChange={(e) => setAddressFormData({ ...addressFormData, contactNumber: e.target.value })}
                    required
                  />

                  <label>Street:</label>
                  <input
                    type="text"
                    value={addressFormData.street}
                    onChange={(e) => setAddressFormData({ ...addressFormData, street: e.target.value })}
                    required
                  />

                  <label>City:</label>
                  <input
                    type="text"
                    value={addressFormData.city}
                    onChange={(e) => setAddressFormData({ ...addressFormData, city: e.target.value })}
                    required
                  />

                  <label>State:</label>
                  <input
                    type="text"
                    value={addressFormData.state}
                    onChange={(e) => setAddressFormData({ ...addressFormData, state: e.target.value })}
                    required
                  />

                  <label>Postal Code:</label>
                  <input
                    type="text"
                    value={addressFormData.postalCode}
                    onChange={(e) => setAddressFormData({ ...addressFormData, postalCode: e.target.value })}
                    required
                  />

                  <GoogleMapComponent onSelectLocation={handleLocationSelect} />
                </div>

                <div className="button-container32">
                  <button type="submit" className="save-button32">Save</button>
                  <button type="button" className="cancel-button32" onClick={() => setShowPopup(false)}>Cancel</button>
                  <button className="close-button32" onClick={() => setShowPopup(false)}>Close</button>
                </div>
              </form>
            </div>
          </div>
        )}



        {/* Delete Confirmation Modal */}
        {showModal && (
          <div className="modal-overlayb">
            <div className="modal-contentb">
              <p>Are you sure you want to delete the {customerToDelete.name}?</p>
              <div className="button-containerb">
                <button className='Confirm' onClick={handleConfirmDelete}>Yes</button>
                <button className='Cancel' onClick={handleCancelDelete}>No</button>
              </div>
            </div>
          </div>
        )}

        <div className="pagination1">
          <Stack spacing={2}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.page}
              onChange={handlePageChange}
              hidePrevButton={false}
              hideNextButton={false}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  className={item.type === 'page' ? 'MuiPaginationItem-page' : item.type === 'previous' || item.type === 'next' ? `MuiPaginationItem-${item.type}` : ''}
                  selected={item.page === pagination.page}
                  slots={{
                    previous: () => <span>Previous</span>,
                    next: () => <span>Next</span>,
                  }}
                />
              )}
            />
          </Stack>
        </div>

      </div>
    </div>
  );
};

export default CustomerList;
