import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';
import '../../styles/Order.css';
import Navbar from '../Navbar/Navbar';
import GoogleMapComponent from '../../components/GoogleMap/GoogleMap';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
// import AddressInput from '../AddessInputComponent/AddressInputComponent';
import { notification } from 'antd';


const OrderList = () => {
  const [orders, setorders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [orderToDelete, setorderToDelete] = useState(null);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editOrdeData, seteditOrdeData] = useState({});
  const [selectedVendor, setselectedVendor] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [newOrderData, setNewOrderData] = useState({
    vendorId: localStorage.getItem('userid'),
    customerId: '',
    customerAddressId: '',
    vendorId: '',
    orderAmount: '',
    paymentType: 'PREPAID',
    deliverySlotType: 'INSTANT',
    scheduledDeliveryDate: null
  });
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    page: '',
    size: '',
    totalElements: '',
    totalPages: '',
  });
  // Fetch orders on component mount
  useEffect(() => {
    fetchorders();
  }, [pagination.page, pagination.size]);

  const handlePageChange = (event, value) => {
    setPagination((prev) => ({
      ...prev,
      page: value,
    }));
  };
  const [addressFormData, setAddressFormData] = useState({
    userId: '',
    latitude: 0,
    longitude: 0,
    formattedAddress: "",
    placeId: ""
  });

  const [showAddAddressPopup, setShowAddAddressPopup] = useState(false);

  const [addresses, setAddresses] = useState([]); // New state for addresses


  // Function to fetch orders
  const fetchorders = async () => {
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      const response = await axios.get(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.ORDER_URL}?page=${pagination.page}&size=${pagination.size}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );

      if (Array.isArray(response.data.content)) {
        setorders(response.data.content);
        setPagination({
          hasNextPage: response.data.hasNextPage,
          hasPreviousPage: response.data.hasPreviousPage,
          page: response.data.page,
          size: response.data.size,
          totalElements: response.data.totalElements,
          totalPages: response.data.totalPages,
        })
      } else {
        console.error('Unexpected data format:', response.data);
        setorders([]);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setError('Error fetching orders. Please try again later.');
      setLoading(false);
    }
  };


  // Fetch orders on component mount
  useEffect(() => {
    fetchorders();
  }, [pagination.page, pagination.size]);

  const handleCustomerSelection = async (user) => {
    setSelectedCustomer(user);
    setNewOrderData(prevData => ({
      ...prevData,
      customerId: user.id
    }));
    console.log('Selected Customer ID:', user.id);

    try {
      const accessToken = localStorage.getItem('token');
      if (!accessToken) {
        throw new Error('No token found');
      }

      const response = await axios.get(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.ADDRESS_URL}/${user.id}/list`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      if (Array.isArray(response.data.addresses)) {
        setAddresses(response.data.addresses);
      } else {
        console.error('Unexpected data format:', response.data);
        setAddresses([]);
      }
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };
  const handleAddressSelection = (event) => {
    const selectedAddressId = event.target.value;

    if (selectedAddressId) {
      // Update the new order data state with the selected customer address ID
      setNewOrderData(prevData => ({
        ...prevData,
        customerAddressId: selectedAddressId
      }));
    }
  };


  // const handleLocationSelect = (location) => {
  //   setFormData(prevData => ({
  //     ...prevData,
  //     latitude: location.lat,
  //     longitude: location.lng,
  //     formattedAddress: location.formattedAddress,
  //     placeId: location.placeId
  //   }));
  // };

  const handleAddAdressInputChange = (e) => {
    const { name, value } = e.target;
    setAddressFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleMapClick = (locationData) => {
    const { lat, lng, formattedAddress, placeId } = locationData;

    setAddressFormData(prevData => ({
      ...prevData,
      latitude: lat,
      longitude: lng,
      formattedAddress,
      placeId
    }));
  };

  const handleAddAddress = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to an API
    console.log('Address Data:', addressFormData);
    setShowAddAddressPopup(false);
  };

  const handleEditClick = (order) => {
    seteditOrdeData(order);
    setShowEditPopup(true);
  };

  const handleEditOrder = async (event) => {
    event.preventDefault();
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      const response = await axios.put(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.ORDER_URL}/${editOrdeData.id}`,
        editOrdeData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        const updatedOrders = orders.map(order =>
          order.id === editOrdeData.id ? editOrdeData : order
        );
        setorders(updatedOrders);
        setShowEditPopup(false);
        // Show success notification
        notification.success({
          message: 'Success',
          description: 'Order updated successfully!',
          duration: 1,
        });
      } else {
        console.error('Failed to edit order:', response);
        // Show success notification
        notification.error({
          message: 'Error',
          description: 'Failed to Order update',
          duration: 1,
        });
      }
    } catch (error) {
      console.error('Error editing order:', error);
      // Show success notification
      notification.error({
        message: 'Error',
        description: 'Error editing order',
        duration: 1,
      });
    }
  };

  const handleEditClickStatus = (order) => {
    seteditOrdeData(order);
    setShowEditPopup(true);
  };



  const handleStatusChange = async (event, order) => {
    const newStatus = event.target.value;

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) throw new Error('No token found');

      const response = await axios.put(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.ORDER_URL}/${order.id}/status`,
        { status: newStatus },
        { headers: { Authorization: `Bearer ${access_token}` } }
      );

      if (response.status === 200) {
        // Update the order status in state
        setorders((prevOrders) =>
          prevOrders.map((o) =>
            o.id === order.id ? { ...o, status: newStatus } : o
          )
        );

        // Show success notification
        notification.success({
          message: 'Success',
          description: 'Order status updated successfully!',
          duration: 1,
        });
      }
    } catch (error) {
      setError('Error updating order status.');
      // Show error notification
      notification.error({
        message: 'Error',
        description: 'There was an error updating the order status.',
        duration: 1,
      });
    }
  };

  const handleSaveAddress = async (event) => {
    event.preventDefault();
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      const response = await axios.post(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.ADD_ADDRESS}/${selectedCustomer.id}`,
        addressFormData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setNewOrderData(prevData => ({
          ...prevData,
          customerAddressId: response.data.address.id
        }));
        setShowAddAddressPopup(false);
        // toast.success('Address added successfully!');
      } else {
        console.error('Failed to add address:', response);
      }
    } catch (error) {
      console.error('Error adding address:', error);
      setError('Error adding address. Please try again later.');
      // toast.error('Error adding address. Please try again.');
    }
  };


  const handleAddAddressClick = (customer) => {

    setShowAddAddressPopup(true);
  };


  const handleDeleteClick = (order) => {
    setorderToDelete(order);
    setShowModal(true);
  };


  const handleConfirmDelete = async () => {
    setShowModal(false);
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      const response = await axios.delete(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.ORDER_URL}/${orderToDelete.id}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );

      if (response.status === 200) {
        // Show success notification
        notification.success({
          message: 'Success',
          description: 'Order deleted successfully!',
          duration: 1,
        }); const updatedOrders = orders.filter(order => order.id !== orderToDelete.id);
        setorders(updatedOrders);
      } else {
        notification.error({
          message: 'Error',
          description: 'Failed to delete order!',
          duration: 1,
        });
      }
    } catch (error) {
      console.error('Error deleting order:', error);
      notification.error({
        message: 'Error',
        description: 'Error deleting order',
        duration: 1,
      });
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
  };

  const handleAddOrder = async (event) => {
    event.preventDefault();
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      const response = await axios.post(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.ORDER_URL}`,
        newOrderData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setShowAddPopup(false);
        // Show success notification
        notification.success({
          message: 'Success',
          description: 'Order created successfully!',
          duration: 1,
        }); fetchorders();
      } else {
        console.error('Failed to add order:', response);
        // Show success notification
        notification.error({
          message: 'Error',
          description: 'Failed to add order!',
          duration: 1,
        });
      }
    } catch (error) {
      console.error('Error adding order:', error);
      // Show success notification
      notification.error({
        message: 'Error',
        description: 'Order status updated successfully!',
        duration: 1,
      });
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredorders = orders.filter(
    (order) =>
      order && order.customer && order.customer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddCustomerClick = () => {
    setShowAddPopup(true);
  };

  const handleClosePopup = () => {
    setShowAddPopup(false);
  };

  // const handlecustomerselection = (user) => {
  //   setselectedOrder(user);
  // };

  const handlevendorselection = (vendor) => {
    setselectedVendor(vendor);
    setNewOrderData(prevData => ({
      ...prevData,
      vendorId: vendor.id
    }));
  };
  const handleLocationSelect = (location) => {
    setAddressFormData(prevData => ({
      ...prevData,
      latitude: location.latitude,
      longitude: location.longitude,
      formattedAddress: location.formattedAddress,
      placeId: location.placeId,
      address: location.formattedAddress
    }));

    console.log(addressFormData);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);

    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('en-US', { month: 'short' }).toUpperCase();
    const year = date.getFullYear().toString().slice(-2);
    const time = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

    return `${day} ${month} ${year} | ${time}`;
  }

  // const handleAddressChange = (address) => {
  //   setNewOrderData((prevData) => ({
  //     ...prevData,
  //     address,
  //   }));
  // };

  // const handleAddressSelect = (location) => {
  //   // Handle location selection, e.g., store in state or pass to API
  //   console.log('Selected location:', location);
  // };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Navbar />

      <div className="customer-list-container">
        <div className="customer-list-actions">
          <input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input06"
          />
          <h1>Order List</h1>
          <button className="add-customer-button" onClick={handleAddCustomerClick}>Add Order</button>
        </div>
        <div className="table-responsive">
          <table className="customer-list-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Order ID</th>
                <th>Customer Name</th>
                <th>Vendor Name</th>
                <th>Amount</th>
                <th>Order Date</th>
                <th>PaymentType</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredorders.map((order, index) => (
                <tr key={order.id}>
                  <td>{index + 1}</td>
                  <td>{order.id}</td>
                  <td>{order.customer.name}</td>
                  <td>{order.vendor.name}</td>
                  <td>{order.orderAmount}</td>
                  <td>{formatDate(order.createdAt)}</td>
                  <td>{order.paymentType}</td>
                  <td>
                    <select
                      value={order.status}
                      onChange={(e) => handleStatusChange(e, order)}
                    >
                      <option value="PENDING">PENDING</option>
                      <option value="CONFIRMED">CONFIRMED</option>
                      <option value="SHIPPED">SHIPPED</option>
                      <option value="DELIVERED">DELIVERED</option>
                      <option value="CANCELLED">CANCELLED</option>
                      <option value="FAILED">FAILED</option>

                      {/* Add other status options as needed */}
                    </select>
                  </td>
                  <td data-label="Actions">
                    <div className="action-buttons-container">
                      <button className="edit-button19" onClick={() => handleEditClick(order)}>Edit</button>
                      <button className="delete-button19" onClick={() => handleDeleteClick(order)}>Delete</button>
                      {/* <button className="add-address-button" onClick={() => handleAddAddressClick(customer)}>Add Address</button> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination1">
            <Stack spacing={2}>
              <Pagination
                count={pagination.totalPages}
                page={pagination.page}
                onChange={handlePageChange}
                hidePrevButton={false}
                hideNextButton={false}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    className={item.type === 'page' ? 'MuiPaginationItem-page' : item.type === 'previous' || item.type === 'next' ? `MuiPaginationItem-${item.type}` : ''}
                    selected={item.page === pagination.page}
                    slots={{
                      previous: () => <span>Previous</span>,
                      next: () => <span>Next</span>,
                    }}
                  />
                )}
              />
            </Stack>
          </div>
        </div>


        {showAddPopup && (
          <div className="create-order-popup">
            <div className="create-order-popup-content">
              <h2>Add Order</h2>
              <form onSubmit={handleAddOrder}>
                <div className="form-container">

                  <div className="input-group">
                    <label>Name:</label>
                    <input
                      type="text"
                      value={newOrderData.name}
                      onChange={(e) => setNewOrderData({ ...newOrderData, name: e.target.value })}
                      required
                    />
                  </div>

                  <div className="input-group">
                    <label>Mobile number:</label>
                    <input
                      type="text"
                      value={newOrderData.mobileNumber}
                      onChange={(e) => setNewOrderData({ ...newOrderData, mobileNumber: e.target.value })}
                      required
                      pattern="\d{10}"
                      maxLength="10"
                    />
                  </div>

                  <div className="input-group">
                    <label>Delivery Location:</label>
                    <div className="google-map-container">
                      <GoogleMapComponent onSelectLocation={handleLocationSelect} />
                    </div>
                  </div>
                  <div className="input-group order">
                    <label>Payment Option:</label>
                    <div>
                      <label>
                        <input
                          type="radio"
                          name="paymentOption"
                          value="PREPAID"
                          checked={newOrderData.paymentType === "PREPAID"}
                          onChange={(e) => setNewOrderData({ ...newOrderData, paymentType: e.target.value })}
                          required
                        />
                        Prepaid
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="paymentOption"
                          value="POD"
                          checked={newOrderData.paymentType === "POD"}
                          onChange={(e) => setNewOrderData({ ...newOrderData, paymentType: e.target.value })}
                          required
                        />
                        COD
                      </label>
                    </div>
                  </div>

                  {newOrderData.paymentType === "POD" && (
                    <div className="input-group">
                      <label>Order Amount:</label>
                      <input
                        type="number"
                        value={newOrderData.orderAmount}
                        onChange={(e) => setNewOrderData({ ...newOrderData, orderAmount: e.target.value })}
                        required
                      />
                    </div>
                  )}




                </div>

                <div className="button-container32">
                  <button type="button" className="cancel-button32" onClick={handleClosePopup}>Cancel</button>
                  <button type="submit" className="save-button32">Add</button>
                  <button type="button" className="close-button32" onClick={handleClosePopup}>Close</button>
                </div>
              </form>
            </div>
          </div>
        )}



        {showEditPopup && (
          <div className="customer-popup">
            <div className="customer-popup-content">
              <h2>Edit Order</h2>
              <form onSubmit={handleEditOrder}> {/* Use handleEditOrder for the form */}
                <div className="form-container">
                  {/* Input fields */}
                  <div className="input-group">
                    <label>Name:</label>
                    <input
                      type="text"
                      value={editOrdeData.name}
                      onChange={(e) => seteditOrdeData({ ...editOrdeData, name: e.target.value })}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <label>Mobile number:</label>
                    <input
                      type="text"
                      value={editOrdeData.mobileNumber}
                      onChange={(e) => seteditOrdeData({ ...editOrdeData, mobileNumber: e.target.value })}
                      required
                      pattern="\d{10}"
                      maxLength="10"
                    />
                  </div>

                  <div className="input-group">
                    <label>Delivery Location:</label>
                    <div className="google-map-container">
                      <GoogleMapComponent onSelectLocation={handleLocationSelect} />
                    </div>
                  </div>

                  <div className="input-group order">
                    <label>Payment Option:</label>
                    <div>
                      <label>
                        <input
                          type="radio"
                          name="paymentOption"
                          value="PREPAID"
                          checked={editOrdeData.paymentType === "PREPAID"}
                          onChange={(e) => seteditOrdeData({ ...editOrdeData, paymentType: e.target.value })}
                          required
                        />
                        Prepaid
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="paymentOption"
                          value="POD"
                          checked={editOrdeData.paymentType === "POD"}
                          onChange={(e) => seteditOrdeData({ ...editOrdeData, paymentType: e.target.value })}
                          required
                        />
                        COD
                      </label>
                    </div>
                  </div>

                  {editOrdeData.paymentType === "POD" && (
                    <div className="input-group">
                      <label>Order Amount:</label>
                      <input
                        type="number"
                        value={editOrdeData.orderAmount}
                        onChange={(e) => seteditOrdeData({ ...editOrdeData, orderAmount: e.target.value })}
                        required
                      />
                    </div>
                  )}

                </div>

                {/* Buttons */}
                <div className="button-container32">
                  <button type="button" className="cancel-button32" onClick={() => setShowEditPopup(false)}>Cancel</button>
                  <button type="submit" className="save-button32">Update</button>
                  <button className="close-button32" onClick={() => setShowEditPopup(false)}>Close</button>
                </div>
              </form>
            </div>
          </div>
        )}


        {showAddAddressPopup && (
          <div className="customer-popup">
            <div className="customer-popup-content">
              <h2>Add Address</h2>
              <form onSubmit={handleAddAddress}>
                <div className="form-row">

                  <div className="form-group-cust">
                    <label>Door No:</label>
                    <input
                      type="text"
                      name="doorNo"
                      value={addressFormData.doorNo}
                      onChange={handleAddAdressInputChange}
                      placeholder="Door No"
                      required
                    />
                  </div>
                  <div className="form-group-cust">
                    <label>Contact Person:</label>
                    <input
                      type="text"
                      name="contactPerson"
                      value={addressFormData.contactPerson}
                      onChange={handleAddAdressInputChange}
                      placeholder="Contact Person"
                      required
                    />
                  </div>
                  <div className="form-group-cust">
                    <label>Contact Number:</label>
                    <input
                      type="text"
                      name="contactNumber"
                      value={addressFormData.contactNumber}
                      onChange={handleAddAdressInputChange}
                      placeholder="Contact Number"
                      required
                    />
                  </div>
                  <div className="form-group-cust">
                    <label>Street:</label>
                    <input
                      type="text"
                      name="street"
                      value={addressFormData.street}
                      onChange={handleAddAdressInputChange}
                      placeholder="Street"
                      required
                    />
                  </div>
                  <div className="form-group-cust">
                    <label>City:</label>
                    <input
                      type="text"
                      name="city"
                      value={addressFormData.city}
                      onChange={handleAddAdressInputChange}
                      placeholder="City"
                      required
                    />
                  </div>
                  <div className="form-group-cust">
                    <label>State:</label>
                    <input
                      type="text"
                      name="state"
                      value={addressFormData.state}
                      onChange={handleAddAdressInputChange}
                      placeholder="State"
                      required
                    />
                  </div>
                  <div className="form-group-cust">
                    <label>Postal Code:</label>
                    <input
                      type="text"
                      name="postalCode"
                      value={addressFormData.postalCode}
                      onChange={handleAddAdressInputChange}
                      placeholder="Postal Code"
                      required
                    />
                  </div>

                  {/* Google Map Component */}
                  <div className="form-group-cust map-container">
                    <label>Select Location:</label>
                    <GoogleMapComponent onSelectLocation={handleMapClick} />
                  </div>

                  {/* Display Selected Location Details */}
                  <div className="form-group-cust location-details">
                    <p><strong>Formatted Address:</strong> {addressFormData.formattedAddress}</p>
                    <p><strong>Latitude:</strong> {addressFormData.latitude}</p>
                    <p><strong>Longitude:</strong> {addressFormData.longitude}</p>
                    <p><strong>Place ID:</strong> {addressFormData.placeId}</p>
                  </div>

                  <div className="button-container32">
                    <button type="submit" className="save-button32">Save</button>
                    <button type="button" className="cancel-button32" onClick={() => setShowAddAddressPopup(false)}>Cancel</button>
                    <button type="button" className="close-button32" onClick={() => setShowAddAddressPopup(false)}>Close</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}


        {/* Delete Confirmation Modal */}
        {showModal && (
          <div className="modal-overlayb">
            <div className="modal-contentb">
              <p>Are you sure you want to delete the {orderToDelete.name}?</p>
              <div className="button-containerb">
                <button className='Confirm' onClick={handleConfirmDelete}>Yes</button>
                <button className='Cancel' onClick={handleCancelDelete}>No</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderList;
